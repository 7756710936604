import { Link, useLocation } from 'react-router';
import {
  Sheet,
  SheetTrigger,
  SheetContent,
  SheetHeader,
  SheetTitle,
  SheetDescription,
} from '@/components/ui/sheet';
import { Button } from '@/components/ui/button';
import { cn } from '@/lib/utils';
import Logo from '@/assets/logo/logo_av.svg';
import { SVGProps } from 'react';
import { menu } from '@/config';
import AuthButton from '../auth-button';

export default function MobileNav() {
  const location = useLocation();

  return (
    <div className="flex justify-between items-center w-full h-20 lg:hidden">
      <Link to="/" className="mr-4">
        <img className="w-10 h-10" src={Logo} alt="AgileVision logo" />
      </Link>
      <Sheet>
        <SheetTrigger asChild>
          <Button variant="ghost" size="icon">
            <MenuIcon className="h-6 w-6" />
            <span className="sr-only">Toggle navigation menu</span>
          </Button>
        </SheetTrigger>
        <SheetContent side="left">
          <SheetHeader className="hidden">
            <SheetTitle></SheetTitle>
            <SheetDescription></SheetDescription>
          </SheetHeader>
          <Link to="/">
            <img className="w-10 h-10 ml-2" src={Logo} alt="AgileVision logo" />
            <span className="sr-only">AgileVision</span>
          </Link>
          <div className="grid gap-2 py-6">
            {menu.map(({ title, to }) => (
              <Link
                key={to}
                to={to}
                className={cn(
                  'flex items-center w-full py-2 px-4 rounded-full text-lg',
                  location.pathname === to && 'bg-blue-100'
                )}
              >
                {title}
              </Link>
            ))}
            <AuthButton />
          </div>
        </SheetContent>
      </Sheet>
    </div>
  );
}

function MenuIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <line x1="4" x2="20" y1="12" y2="12" />
      <line x1="4" x2="20" y1="6" y2="6" />
      <line x1="4" x2="20" y1="18" y2="18" />
    </svg>
  );
}
