import { router } from '@/router';
import { useAuth0 } from '@auth0/auth0-react';
import { RouterProvider } from 'react-router/dom';
import { Loader } from '@/components/loader';

function App() {
  const { isLoading } = useAuth0();

  if (isLoading) {
    return <Loader />;
  }

  return <RouterProvider router={router} />;
}

export default App;
