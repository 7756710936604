import { useState } from 'react';
import { Row } from '@tanstack/react-table';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { Button } from '@/components/ui/button';
import { Icons } from '@/components/icons';
import { ModalDialog } from '@/components/modal-dialog';
import { ScenarioDto } from '@/generated/SyncMyOrdersApi';

interface RowActionsColumnProps {
  row: Row<ScenarioDto>;
  onUpdate: (value: ScenarioDto) => void;
  onDelete: (value: ScenarioDto) => void;
}

export function RowActionsColumn(props: RowActionsColumnProps) {
  const { row, onUpdate, onDelete } = props;

  const [isOpen, setOpen] = useState(false);

  const scenario = row.original;

  const handleRemove = () => {
    setOpen(false);
    onDelete(scenario);
  };

  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="ghost" className="h-8 w-8 p-0">
            <span className="sr-only">Open menu</span>
            <Icons.MoreHorizontal className="h-4 w-4" />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end">
          <DropdownMenuLabel>Actions</DropdownMenuLabel>
          <DropdownMenuItem onClick={() => onUpdate(scenario)}>
            <Icons.edit className="mr-2 h-4 w-4" />
            Edit
          </DropdownMenuItem>
          <DropdownMenuSeparator />
          <DropdownMenuItem onClick={() => setOpen(true)}>
            <Icons.remove className="mr-2 h-4 w-4" />
            Delete
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
      <ModalDialog
        open={isOpen}
        description={`This action will delete the scenario (${scenario.name}).`}
        onConfirm={handleRemove}
        onClose={() => setOpen(false)}
      />
    </>
  );
}
