import { Skeleton } from '@/components/ui/skeleton';
import { range } from '@/lib/utils';

export function SkeletonTable() {
  return (
    <div className="flex flex-col space-y-3">
      <Skeleton className="bg-blue-200 w-full h-10 rounded-md" />
      {range(5).map((item) => (
        <Skeleton key={item} className="bg-card w-full h-12 rounded-md" />
      ))}
    </div>
  );
}
