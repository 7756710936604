const operator = {
  slack: 'SlackBotMessageOperator',
  msdbcExtensiv: 'PredefinedMSDBCExtensivOperator',
};

export const availableOperators = [
  {
    title: 'Slack',
    name: 'slack',
    description:
      'Slack is a messaging app for business that connects people to the information they need.',
    operatorName: operator.slack,
  },
  {
    title: 'MSDBC / Extensiv Integration',
    name: 'msdbcExtensiv',
    description: 'Predefined integration',
    operatorName: operator.msdbcExtensiv,
  },
];
