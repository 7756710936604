import { Link } from 'react-router';
import { PlusIcon } from '@radix-ui/react-icons';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { Button } from '@/components/ui/button';
import { availableOperators } from '@/config/connections';
import { Icons } from '@/components/icons';

export function CreateConnectionButton() {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button>
          <PlusIcon className="mr-2 h-4 w-4" />
          Create connection
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-56" align="start">
        <DropdownMenuGroup>
          {availableOperators.map(({ title, name, operatorName }) => (
            <Link key={operatorName} to={`connection/${operatorName}/create`}>
              <DropdownMenuItem>
                <div className="flex flex-col space-y-3">
                  <div className="flex items-center">
                    <img
                      className="h-6 w-6 mr-3"
                      src={Icons[name]}
                      alt="Slack logo"
                    />
                    <p>{title}</p>
                  </div>
                </div>
              </DropdownMenuItem>
            </Link>
          ))}
        </DropdownMenuGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
