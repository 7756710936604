import { useAuth0 } from '@auth0/auth0-react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { toast } from 'sonner';

export function useCustomQuery({ queryKey, queryFn, ...options }) {
  const auth = useAuth0();

  const customQueryFn = async (context) => {
    const token = await auth.getAccessTokenSilently();
    return queryFn(token, context);
  };

  const customOptions = {
    refetchOnWindowFocus: false,
    ...options,
  };

  return useQuery({
    queryKey,
    queryFn: customQueryFn,
    ...customOptions,
  });
}

export function useCustomMutation({ mutationFn, ...options }) {
  const auth = useAuth0();

  const customMutationFn = async (data) => {
    const token = await auth.getAccessTokenSilently();
    return mutationFn(token, data);
  };

  const handleError = (error) => {
    toast.error(`Error: ${error.status}`, {
      description: error.message,
    });
  };

  return useMutation({
    mutationFn: customMutationFn,
    ...options,
    onError: handleError,
  });
}
