import { Link } from 'react-router';
import { PlusIcon } from '@radix-ui/react-icons';
import { Button } from '@/components/ui/button';

export function CreateScenarioButton() {
  return (
    <Link to="/scenario/create">
      <Button>
        <PlusIcon className="mr-2 h-4 w-4" />
        Create scenario
      </Button>
    </Link>
  );
}
