import {
  Loader2,
  LogInIcon,
  LogOut,
  MoreHorizontal,
  SquarePen,
  Trash2,
} from 'lucide-react';
import SlackLogo from '@/assets/logo/slack_logo.svg';

export const Icons: Record<string, any> = {
  spinner: Loader2,
  signIn: LogInIcon,
  signOut: LogOut,
  MoreHorizontal: MoreHorizontal,
  edit: SquarePen,
  remove: Trash2,
  slack: SlackLogo,
  msdbcExtensiv: SlackLogo,
};
