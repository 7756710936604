import { ConnectionForm } from '@/components/connection-form';
import { ModalDialog } from '@/components/modal-dialog';
import { useCustomMutation, useCustomQuery } from '@/hooks/api';
import {
  getConnectionById,
  removeConnection,
  updateConnection,
} from '@/queries';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { QueryKeys } from '@/queries/query-keys';
import { SkeletonForm } from '@/components/skeleton-form';
import { toast } from 'sonner';

export function Connection() {
  const [isOpen, setOpen] = useState(false);

  const { operatorId, connectionId } = useParams();

  const navigate = useNavigate();

  const { data, isFetching } = useCustomQuery({
    queryKey: [QueryKeys.Connection, operatorId, connectionId],
    queryFn: getConnectionById,
  });

  const updateMutation = useCustomMutation({
    mutationFn: updateConnection,
    onSuccess: () => {
      toast.info('Connection successfully updated');
    },
  });

  const removeMutation = useCustomMutation({
    mutationFn: removeConnection,
    onSuccess: () => {
      navigate('/');
      toast.success('Connection has been removed');
    },
  });

  const handleSubmit = (data: Record<string, unknown>) => {
    updateMutation.mutate({
      operatorId,
      connectionId,
      parameters: data,
    });
  };

  const handleRemove = () => {
    setOpen(false);
    removeMutation.mutate(connectionId);
  };

  const loading = updateMutation.isPending || removeMutation.isPending;

  if (isFetching) {
    return <SkeletonForm />;
  }

  return (
    <section>
      <ConnectionForm
        isEdit
        title="Edit connection"
        fieldsSchema={data}
        loading={loading}
        onRemove={() => setOpen(true)}
        onSubmit={handleSubmit}
      />
      <ModalDialog
        open={isOpen}
        description="This action will delete the connection."
        onConfirm={handleRemove}
        onClose={() => setOpen(false)}
      />
    </section>
  );
}
