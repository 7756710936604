import { CreateScenarioButton } from '@/components/create-scenario-button';
import { scenariosColumns } from '@/components/scenarios-table/columns';
import { DataTable } from '@/components/scenarios-table';
import { ScenarioDto } from '@/generated/SyncMyOrdersApi';
import { useCustomMutation, useCustomQuery } from '@/hooks/api';
import { toast } from 'sonner';
import { getScenarios, removeScenario } from '@/queries';
import { QueryKeys } from '@/queries/query-keys';
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router';
import { queryClient } from '@/main';

export function Scenarios() {
  const navigate = useNavigate();

  const { data: scenarios, isFetching } = useCustomQuery({
    queryKey: [QueryKeys.Scenarios],
    queryFn: getScenarios,
  });

  // const updateMutation = useCustomMutation({
  //   mutationFn: updateScenario,
  //   onSuccess: () => {
  //     toast.success('Scenario successfully updated');
  //   },
  // });

  const removeMutation = useCustomMutation({
    mutationFn: removeScenario,
    onSuccess: () => {
      toast.success('Scenario has been removed');
      queryClient.invalidateQueries({ queryKey: [QueryKeys.Scenarios] });
    },
  });

  const handleUpdate = useCallback(
    (scenario: ScenarioDto) => {
      // updateMutation.mutate(scenario);
      navigate(`/scenarios/${scenario.id}`);
    },
    [navigate]
  );

  const handleDelete = useCallback(
    (scenario: ScenarioDto) => {
      removeMutation.mutate(scenario.id);
    },
    [removeMutation]
  );

  const columns = useMemo(
    () =>
      scenariosColumns({
        onUpdate: handleUpdate,
        onDelete: handleDelete,
      }),
    [handleDelete, handleUpdate]
  );

  return (
    <section>
      <CreateScenarioButton />
      <h1 className="my-6">Scenarios</h1>
      <div>
        <DataTable columns={columns} data={scenarios} isFetching={isFetching} />
      </div>
    </section>
  );
}
