import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Navigate } from 'react-router';

interface PrivateRouteProps {
  children: React.ReactNode;
}

export function PrivateRoute(props: PrivateRouteProps) {
  const { children } = props;

  const { isAuthenticated } = useAuth0();

  return isAuthenticated ? children : <Navigate to="/login" />;
}
