import { inputTypes } from '@/config';
import * as API from '@/generated/SyncMyOrdersApi.ts';

export const REST = new API.Api({
  format: 'json',
  baseURL: import.meta.env.VITE_SMO_API_BASE_URL,
});

export async function getConnections(token: string) {
  const result = await REST.api.getConnections({
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return result.data;
}

export async function getConnectionSchema(token: string, context: any) {
  const [, connectionType] = context.queryKey;

  const { data } = await REST.api.getConnectionSchemaForOperatorByName(
    connectionType,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return data.map((field) => ({ ...field, value: '' }));
}

export async function getConnectionById(token: string, context: any) {
  const [, operatorId, connectionId] = context.queryKey;

  const { data } = await REST.api.getConnectionSchemaForOperatorByName(
    operatorId,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  const { data: connection } = await REST.api.getConnection(connectionId, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  const { title, connectionData } = connection;
  const { parameters = {} } = connectionData || {};

  const connectionSchema = data.map((connection) => ({
    ...connection,
    value: connection.name ? parameters[connection.name] : '',
  }));

  const extraSchema = [
    {
      type: inputTypes.TypeString,
      name: 'title',
      value: title,
    },
  ];

  return [...extraSchema, ...connectionSchema];
}

export async function createConnection(
  token: string,
  connection: API.ConnectionDataDto
) {
  await REST.api.createConnection(connection, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function updateConnection(token: string, newConnection: any) {
  const { connectionId, operatorId, parameters } = newConnection;

  const { title, ...restParam } = parameters;

  const query = {
    id: connectionId,
    title,
    connectionData: {
      parameters: restParam,
    },
    operatorName: operatorId,
  };

  await REST.api.updateConnection(connectionId, query, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function removeConnection(token: string, connectionId: string) {
  await REST.api.deleteConnection(connectionId, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function getScenarios(token: string) {
  const result = await REST.api.getAllScenarios({
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return result.data;
}

export async function updateScenario(token: string, newScenario: any) {
  const { parameters } = newScenario;

  const { title } = parameters;

  const query = { title };

  await REST.api.updateConnection('', query, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function removeScenario(token: string, scenarioId: string) {
  await REST.api.deleteScenario(scenarioId, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}
