import { ScenarioDto } from '@/generated/SyncMyOrdersApi';
import { formatDate } from '@/lib/utils';
import { ColumnDef } from '@tanstack/react-table';
import { RowActionsColumn } from './row-actions-column';

interface ScenariosColumnsProps {
  onUpdate: (scenario: ScenarioDto) => void;
  onDelete: (scenarioId: ScenarioDto) => void;
}

export const scenariosColumns = ({
  onUpdate,
  onDelete,
}: ScenariosColumnsProps): ColumnDef<ScenarioDto>[] => [
  {
    accessorKey: 'name',
    header: 'Name',
  },
  {
    accessorKey: 'created',
    header: 'Created',
    cell: ({ row }) => {
      const created: string = row.getValue('created');
      return formatDate(created);
    },
  },
  {
    accessorKey: 'updated',
    header: 'Last modified',
    cell: ({ row }) => {
      const updated: string = row.getValue('updated');
      return formatDate(updated);
    },
  },
  {
    id: 'actions',
    // header: 'Actions',
    size: 64,
    cell: ({ row }) => (
      <RowActionsColumn row={row} onUpdate={onUpdate} onDelete={onDelete} />
    ),
  },
];
