import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Auth0Provider } from '@auth0/auth0-react';
import * as Sentry from '@/lib/sentry.ts';
import App from './App.tsx';
import { Toaster } from '@/components/ui/sonner';
import './index.css';

Sentry.init();

export const queryClient = new QueryClient();

createRoot(document.getElementById('root')!).render(
  <StrictMode>
    <Auth0Provider
      domain="dev-1v38sgy36exx5hdh.us.auth0.com"
      clientId="46dFoew0E3yhteixCUgehdBJgsgGdE3T"
      // useRefreshTokens={true}
      authorizationParams={{
        redirect_uri: window.location.origin,
        scope: 'openid profile email',
        audience: 'https://api.syncmyorders.com', // Do not change, this is ID, not a URL
      }}
    >
      <QueryClientProvider client={queryClient}>
        <App />
        <Toaster richColors toastOptions={{}} />
      </QueryClientProvider>
    </Auth0Provider>
  </StrictMode>
);
