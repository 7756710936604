import { Link, useLocation } from 'react-router';
import {
  NavigationMenu,
  NavigationMenuList,
  NavigationMenuLink,
  navigationMenuTriggerStyle,
} from '@/components/ui/navigation-menu';
import Logo from '@/assets/logo/logo_av.svg';
import AuthButton from '@/components/auth-button';
import { menu } from '@/config';

export default function MainNav() {
  const location = useLocation();

  return (
    <div className="hidden w-full h-20 lg:flex lg:justify-between lg:items-center">
      <div className="flex">
        <Link to="/" className="mr-4">
          <img className="w-10 h-10" src={Logo} alt="AgileVision logo" />
        </Link>
        <NavigationMenu>
          <NavigationMenuList>
            {menu.map(({ title, to }) => (
              <NavigationMenuLink
                className={navigationMenuTriggerStyle()}
                key={to}
                asChild
              >
                <Link
                  to={to}
                  className={location.pathname === to ? 'bg-blue-200' : ''}
                >
                  {title}
                </Link>
              </NavigationMenuLink>
            ))}
          </NavigationMenuList>
        </NavigationMenu>
      </div>
      <AuthButton />
    </div>
  );
}
