import { Outlet } from 'react-router';
import MainNav from './main-nav';
import MobileNav from './mobile-nav';
import React from 'react';

interface LayoutProps {
  children?: React.ReactNode;
}

export function Layout({ children }: LayoutProps) {
  return (
    <>
      <header className="container mx-auto px-4 lg:px-8">
        <MainNav />
        <MobileNav />
      </header>
      <main className="container mx-auto px-4 py-6 lg:px-8">
        {children ?? <Outlet />}
      </main>
    </>
  );
}
