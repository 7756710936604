import { clsx, type ClassValue } from 'clsx';
import { twMerge } from 'tailwind-merge';
import { z, ZodTypeAny } from 'zod';
import { ConnectionSchema } from '@/lib/types';
import { inputTypes } from '@/config';
import { format } from 'date-fns';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function camelToHuman(str: string) {
  const humanString = str.replace(/([A-Z])/g, ' $1').toLowerCase();
  return humanString.charAt(0).toUpperCase() + humanString.slice(1);
}

export function mapConnectionDefaultValues(fields: ConnectionSchema[]) {
  return fields.reduce(
    (acc: Record<string, any>, field: ConnectionSchema) => ({
      ...acc,
      [field.name]: field.value,
    }),
    {}
  );
}

export function createZodSchema(fields: ConnectionSchema[]) {
  const schema: Record<string, ZodTypeAny> = {};

  fields.forEach((field) => {
    let fieldSchema!: ZodTypeAny;

    if (field.type === inputTypes.TypeString) {
      fieldSchema = z.string();
    } else if (field.type === inputTypes.TypeBoolean) {
      fieldSchema = z.boolean();
    }

    schema[field.name] = fieldSchema;
  });

  return z.object(schema);
}

export function formatDate(
  date: Date | string,
  pattern: string = 'dd MMM, yyyy p'
) {
  return format(new Date(date), pattern);
}

export const range = (start: number, end?: number, step = 1) => {
  const output = [];

  if (typeof end === 'undefined') {
    end = start;
    start = 0;
  }

  for (let i = start; i < end; i += step) {
    output.push(i);
  }

  return output;
};
